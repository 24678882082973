@import "../../../style/variables.scss";

.LiveSessionCardComponent {
  width: 300px;
  background: $white;
  border-radius: $borderRadiusBig;
  padding: 20px;
  cursor: pointer;
  transition: 200ms all;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 3px;

  .name {
    width: 260px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 1.2rem;
    font-weight: 500;
  }
  .lang{
    font-size: .9rem;
    color: $gray;
    margin: 3px;
  }

  .liveBadgeContainer {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: $white;
    font-size: .8rem;
    padding: 0 4px;
    display: flex;
    gap: 1px;

    .liveTitle, .liveLength {
      background-color: red;
      padding: 0 4px;
    }
  }
  &:hover {
    box-shadow: $ultraLightShadow;
    transition: 200ms all;
  }
}

.rtl {
  .liveBadgeContainer {
    right: unset;
    left: 20px; 
  }
}