@import "../../../style/variables.scss";

.ButtonComponent.MuiButton-root {
  background-color: $mainColorDark;
  text-transform: none;
  font-family: Poppins;
  font-weight: 400;
  &.MuiButton-outlined {
    background-color: transparent;
    border: 2px solid $mainColor;
    color: $mainColor;
    &:hover {
      background-color: $lightGray;
    }
  }
  &:hover {
    background-color: $mainColorHover;
  }
}