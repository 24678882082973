@import "../../style/variables.scss";

$buttonSize: 300px;
$selectionColor: rgba(255, 255, 0, 0.635);

.LiveEditorComponent {
  width: 100%;
  height: 95%;
  border-radius: $borderRadius;
  overflow: hidden;
  background: $white;
  box-shadow: $ultraLightShadow;
  color: $black;
  position: relative;

  display: grid;
  grid-template-rows: 90px auto;
  .liveEditorHeader {
    height: 90px;
    padding: 25px;
    box-sizing: border-box;
    background-color: $mainColor;

    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      width: 100%;
    }
    .jobInfo {
      text-align: center;
      font-size: 1.3rem;
      font-family: Poppins;
      font-weight: 400;
      color: $white;
      .creationTime {
        font-size: 1.1rem;
        font-weight: 300;
      }
    }
    .liveEditorStartActions {
      justify-content: flex-start;
    }
    .liveEditorEndActions {
      justify-content: flex-end;
    }

    .liveEditorStartActions,
    .liveEditorEndActions,
    .jobActions {
      display: flex;
      gap: 40px;
      align-items: center;
      .action {
        color: $white;
        font-size: 2.5rem;
        color: $white;
        &.small {
          font-size: 1.6rem;
        }
      }
      .fontSize {
        display: flex;
        align-items: center;
        gap: 10px;
        .Dropdown {
          color: $black;
          width: 70px;
          height: 35px;
          font-size: 1.1rem;
          .dropdownContainer,
          .selectedLabel {
            background-color: $white;
          }
        }
      }
      .endSession {
        height: 100%;
        font-size: 1.4rem;
        display: flex;
        justify-content: center;
      }

      .action,
      .endJob {
        &:hover {
          cursor: pointer;
          color: $lightGray;
        }
      }
    }
    .jobSearch {
      display: flex;
      justify-content: flex-start;
      input {
        height: 30px;
        width: 200px;
        border-radius: 15px;
        border: 1px solid #5e5e5e;
        padding: 0 10px;
        font-size: 1.1rem;
      }
    }
  }
  .transcript {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    overflow: scroll;
    .transcriptRange {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      min-height: unset;
      gap: 0;
      margin-bottom: 20px;
      .rangeTimes,
      .rangeText {
        display: flex;
        background: white;
        &.rangeTimes {
          min-width: 100px;
          color: gray;
        }
        &.rangeText {
          width: 100%;
          display: inline;
          .transcriptWord {
            padding: 2px;
          }
          &:focus {
            outline: 0;
            border-bottom: 2px solid $lightGray;
          }
          &::selection {
            background-color: $selectionColor;
            border-bottom: 2px solid $lightGray;
          }
        }
      }
    }
    .unstable {
      padding-inline-start: 5px;
      color: lightgrey;
      display: inline-block;
    }
    .scrollToBottomButton {
      width: 60px;
      height: 60px;
      position: absolute;
      bottom: 20px;
      left: 20px;
      opacity: 0.9;
      font-size: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white;
      border-radius: $borderRadius;
      box-shadow: $ultraLightShadow;
      border: 1px solid $lightGray;
      cursor: pointer;
      transition: all 300ms;
      &:hover {
        opacity: 1;
      }
      &.hidden {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .selectedTextActions {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: $white;
    margin: -20px;
    border-radius: 50%;
    border: 2px solid $black;

    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      transform: scaleX(-100%);
    }
    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }

  .robotMessage.noTranscript {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .upperMessage,
    .bottomMessage {
      font-size: 2rem;
      margin: 50px;
      color: $mainColor;
    }
    .robotImage img {
      height: 30vh;
    }
  }

  &.subtitlesMode {
    background-color: #000;
    .transcriptRange {
      .rangeTimes {
        display: none !important;
      }
      .rangeText {
        color: papayawhip;
        background-color: #000;
      }
    }
  }
}

.PendingThreeDots {
  display: inline-block;
  background: linear-gradient(to right, #d8d8d8 20%, #4e4e4e 40%, #1a1a1a 60%, #1a1a1a 80%, #d8d8d8 100%);
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: dotsMove 1s linear infinite;
  @keyframes dotsMove {
    to {
      background-position: 200% center;
    }
  }
}

.recordButton {
  border-radius: 50%;
  
	box-shadow: 0 0 0 0 rgba(145, 0, 0, 1);
	transform: scale(1);
  &.recording {
    background: red;
    animation: pulse 2s infinite;
  }
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(145, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(145, 0, 0, 0.2);
	}

  85% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(145, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}