@import "../../style/variables.scss";

.ManualTranscriptor {
  .connectionContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    .connectBtn {
      width: 100px;
    }
  }
  .connectionStatusContainer {
    display: flex;
    gap: 3px;
    align-items: baseline;
    .indicator {
      font-size: 12px;
      color: $colorNegative;
    }
    &.connected {
      .indicator {
        color: $colorPositive;
      }
    }
  }
  .transcriptContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    padding: 10px;
    .transcript {
      border: 1px solid $lightGray;
      border-radius: $borderRadius;
      padding: 5px;
      height: 60vh;
      font-size: 20px;
      textarea {
        width: 100%;
        height: 100%;
        resize: none;
        outline: none;
        border-radius: $borderRadius;
        border: 0;
        font-size: 28px;
        text-align: right;
      }
      &.ltr {
        .transcriptInput {
          direction: ltr;
          text-align: left;
        }
      }
      &.rtl {
        .transcriptInput {
          direction: rtl;
          text-align: right;
        }
      }
    }
    .transcriptInput {
      font-size: 18px;
      border: 0;
      border-bottom: 1px solid $lightGray;
      outline: 0;
      padding: 5px;
    }
    .disabled {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(1px);
    }
  }
}