// Colors
$mainColor:             #3c5069;
$mainColorDark:         #222936;
$mainColorActive:         $mainColorDark;
$mainColorHover:          darken($mainColor, 6);
$bgColor:               #f3f4f6;
$black:                 #2c2c2c;
$white:                 #fff;
$gray:                  #757575;
$lightGray:             #d6d6d6;

$colorNegative:              #c92424;
$colorPositive:              #3abe26;

// Variables
$borderRadius:            5px;
$borderRadiusBig:         10px;
$ultraLightShadow:        0px 0px 24px rgba($mainColorDark, .2);