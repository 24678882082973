.LobbyPage {
  .liveSessions {
    margin-top: 20px;
    .liveSessionsTitle {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }
    .liveSessionsContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}