@import "../../../style/variables.scss";

.Dropdown {
  width: 300px;
  height: 35px;
  position: relative;
  &.whiteBackground {
    .selectedLabel {
      background-color:$white;
    }
  }
  .selectedLabel,
  .dropdownContainer {
    border-radius: $borderRadius;
    border: 1px solid $gray;
  }
  .selectedLabel {
    padding: 8px;
    font-size: 1rem;
    line-height: 1rem;
    color: $black;
    cursor: pointer;
    &.placeholder {
      color: $gray;
    }
  }
  .dropdownContainer {
    width: 100%;
    position: absolute;
    z-index: 10;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: white;
    top: 28px;
    overflow: hidden;
    &.isOpen {
      padding-top: 8px;
    }
    .search {
      width: 100%;
      padding: 0 8px;
      input {
        width: 100%;
        height: 35px;
        padding: 10px;
        background-color: $lightGray;
        border: none;
        border-radius: 100px;
      }
    }
    .optionsContainer {
      height: 135px;
      overflow-y: scroll;
      overflow-x: hidden;
      .option {
        cursor: pointer;
        padding: 4px 16px;
        display: flex;
        align-items: center;
        gap: 5px;
        input[type="checkbox"] {
          width: 16px;
          height: 16px;
          border-radius: 4px;
          &:after {
            width: 11px;
            margin: 1px 2px;
          }
        }
        &.checkbox {
          display: flex;
          align-items: center;
          cursor: default;
          input,
          label {
            cursor: pointer;
          }
          label {
            width: 100%;
            height: 100%;
          }
        }
        &:hover {
          background-color: #e3e3e3;
        }
      }
    }
    &:not(.isOpen) {
      height: 0;
      border: 0;
    }
  }
  &.mediumDropdown {
    width: 150px;
    .optionsContainer {
      height: 80px;
    }
  }
  .arrow {
    position: absolute;
    top: 10px;
    left: 15px;
    cursor: pointer;
    transition: 200ms all;
  }
  &.rtl {
    .arrow {
      transform: rotate(90deg);
    }
  }
  &.ltr {
    .arrow {
      transform: rotate(-90deg);
      left: auto;
      right: 6px;
    }
  }
  &.isOpen {
    .arrow {
      transform: rotate(0deg);
    }
  }
}
