.LoaderComponent {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  &.transparent {
    backdrop-filter: blur(3px);
  }
}