@import "./style/fonts.scss";
@import "./style/variables.scss";

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Assistant';
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

//REMOVE:
.Layout {
  section {
    width: 100%;
    overflow: hidden;
    grid-template: "page";
    padding: 30px 50px;
    text-align: start;
    background-color: $bgColor;
    position: relative;
    .pageHeader {
      margin-bottom: 20px;
      .pageTitle {
        font-size: 1.3rem;
      }
    }
  }
  .search {
    display: none;
  }
}
