.Subtitler {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: ivory;
  padding: 100px;
  .subtitle {
    width: 100%;
    &.secondarySubtitle {
      scale: 0.8;
      opacity: .8;
    }
  }
  .logoLine {
    width: 100%;
    direction: ltr;
    .poweredBy {
      font-size: 13px;
      margin: 3px 3px;
    }
    .logo {
      width: 80px;
    }
  }

  &.ltr {
    .logoLine {
      text-align: right;
    }
  }
  &.rtl {
    .logoLine {
      text-align: left;
    }
  }
}
