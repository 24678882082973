@import "../.././style/variables.scss";

.StartNewLiveModal {
  width: 400px;
  background: linear-gradient($mainColor 20px, $white 0);
  border-radius: $borderRadiusBig !important;

  align-items: center;
  gap: 15px;
  padding: 20px;
  .modalTitle {
    margin-top: 10px;
    font-size: 1.4rem;
  }
  .modalBody {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .Input {
      width: 300px;
    }
    .Dropdown {
      .optionsContainer {
        height: 90px;
      }
    }
  }
  .buttons {
    display: flex;
    gap: 10px;
  }
}
